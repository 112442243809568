// .header {
//     background-color: map_get($theme-colors, 'primary');
// }

// .link {
//     background-color: map_get($theme-colors, 'secondary');
//     border-radius: 4px;
//     color: #69605a;
//     padding: 2px 6px;
//     text-decoration: none;

//     &:hover {
//         background-color: darken(map_get($theme-colors, 'secondary'), 20%);
//         border-color: darken(map_get($theme-colors, 'secondary'), 20%);
//     }
// }

.navbar {
    @media print {
        display: none;
    }

    // .dropdown-item {
    //     padding: 0;
    // }

    &.is-running-in-dgcs-prod,
    &.is-running-in-cffe-prod,
    &.is-running-in-prod {
        box-shadow: inset 0 3px 0 0 #e8ca01;
    }

    &.is-running-in-dgcs-val,
    &.is-running-in-cffe-val,
    &.is-running-in-val {
        box-shadow: inset 0 3px 0 0 #78a5b6;
    }

    &.is-running-in-local {
        box-shadow: inset 0 3px 0 0 #4f7302;
    }
}

.nav-link {
    --box-shadow-color: #e8ca01;

    transition: box-shadow 0.15s linear;
    box-shadow: 0 0 0 0 var(--box-shadow-color);
    white-space: nowrap;

    .dropdown-menu & {
        margin-left: 5px;
    }

    &.active {
        box-shadow: 0 5px 0 0 var(--box-shadow-color);

        .dropdown-menu & {
            box-shadow: -5px 0 0 0 var(--box-shadow-color);
        }
    }

    &.disabled {
        opacity: 0.5;
    }

    .navbar.is-running-in-dgcs-prod &,
    .navbar.is-running-in-cffe-prod &,
    .navbar.is-running-in-prod & {
        --box-shadow-color: #e8ca01;
    }

    .navbar.is-running-in-dgcs-val &,
    .navbar.is-running-in-cffe-val &,
    .navbar.is-running-in-val & {
        --box-shadow-color: #78a5b6;
    }

    .navbar.is-running-in-local & {
        --box-shadow-color: #4f7302;
    }
}
